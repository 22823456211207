<script setup lang="ts">

</script>

<template>
  <main class="bg-muted-100 dark:bg-muted-900 w-full h-full overflow-y-auto">
    <div class="mx-auto w-full max-w-7xl p-6">
      <ToolBar />
      <slot />
    </div>
  </main>
</template>

<style>

body, html, #__nuxt {
  @apply h-full;
}

#__nuxt > div {
  @apply h-full;
}

/* TODO this actually a workaround and does not fix the root of the issue as these classes should automatically be
    applied, and this doesn't animate the transition. */
#nt-container {
  @apply fixed inset-0 pointer-events-none p-4 flex flex-col-reverse overflow-hidden z-[200] items-center gap-2 space-y-3;
}

</style>
