<script setup lang="ts">
// Imports
const auth = useAuth();
const route = useRoute()
const router = useRouter();

// Data
const currentUser = await useCurrentUser();

function goToParent() {
  if (typeof route.meta.parent === "string") {
    router.push({ path: <string> route.meta.parent, });
  }

  if (typeof route.meta.parent === "function") {
    router.push({ path: route.meta.parent(route), })
  }
}

function logout() {
  auth.logout();
  router.push({ path: "/management/login" });
}
</script>

<template>
  <div
    class="relative z-50 mb-5 flex h-16 items-center gap-2"
  >
    <button class="w-16 h-16 text-md" v-show="route.meta.parent !== undefined" @click="goToParent()">
      <Icon name="ooui:arrow-previous-ltr" />
    </button>

    <BaseHeading
      as="h1"
      size="2xl"
      weight="light"
      class="text-muted-800 hidden dark:text-white md:block"
    >
      <slot name="title">{{ route.meta.title }}</slot>
    </BaseHeading>

    <div class="ms-auto"></div>

    <div class="space-x-4">
      <span>{{ currentUser.full_name }}</span>

      <BaseButtonIcon @click="logout">
        <Icon name="humbleicons:logout" />
      </BaseButtonIcon>
    </div>
  </div>
</template>

<style scoped>

</style>
